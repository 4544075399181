import * as React from 'react';
import Header from '../../components/header';
import SEO from '../../components/seo';

// styles
const pageStyles = {
  color: 'rgb(0 255 0)',
  backgroundColor: 'black',
  padding: '20px 0 96px',
  margin: 0,
  fontFamily: 'Press_Start, Roboto, sans-serif, serif',
  minHeight: '100vh',
};

const imageStyles = {
  maxWidth: '100%',
};

const paragraphStyle = {
  fontSize: '12px',
};

const strikeThrough = {
  textDecoration: 'line-through',
  textDecorationThickness: '5px',
};

const NotFoundPage = () => (
  <div>
    <SEO title="Shitty Mashups" />
    <Header />
    <main style={pageStyles}>
      <div className="container">
        <h4 style={{ marginTop: '90px', marginBottom: '24px' }}>About</h4>
        <br />
        <h4>WARNING:</h4>
        <p style={paragraphStyle}>
          The following information is only available to those with enough government
          clearance to know about the Secret Music Research Facility
          {' '}
          <span style={strikeThrough}>in the Center of the Earth</span>
          {' '}
          on the Surface of the Moon,
          which officially does not exist. Reading or disseminating this information without the
          proper clearance will be punished by drone strike.
        </p>
        <br />
        <br />
        <h4>Description:</h4>
        <p style={paragraphStyle}>
          Shitty Mashups is the result of three music researchers living
          {' '}
          <span style={strikeThrough}>in the Center of the Earth</span>
          {' '}
          for thousands of years in an attempt to create the most
          unmonetizable podcast ever conceived.
        </p>
        <p style={paragraphStyle}>
          Every week the two &quot;smashers&quot; square off by creating mashups based on a given
          challenge, and
          it&apos;s up to the challenge master to decide which one he likes better. The winner gains
          admiration of the
          {' '}
          <span style={strikeThrough}>surface</span>
          {' '}
          earth dwellers, the loser gets the release of death by being sent
          {' '}
          <span style={strikeThrough}>
            out of the airlock, and into the vacuum of space in the center of the earth.
          </span>
          {' '}
          into the incinerator.
        </p>
        <h4 style={{ marginTop: '24px', paddingTop: '24px', borderTop: '3px dashed rgb(0 255 0)' }}>The Challenge Master</h4>
        <div className="row">
          <div className="col-12 col-sm-2">
            <img style={imageStyles} src="/shane.gif" alt="shane's likeness" />
          </div>
          <div className="col-12 col-sm-10">
            <ul style={paragraphStyle}>
              <li>Name: Shane REDACTED</li>
              <li>DOB: REDACTED</li>
              <li>Hometown: REDACTED, Texas</li>
              <li>Fears: Snakes, Harmonic 7ths, February 29th</li>
              <li>Power Rating: 8.6</li>
              <li>Field of Study: Exomelodic Theory</li>
            </ul>
          </div>
        </div>
        <h4 style={{ marginTop: '24px', paddingTop: '24px', borderTop: '3px dashed rgb(0 255 0)' }}>The Smashers</h4>
        <div className="row">
          <div className="col-12 col-sm-2">
            <img style={imageStyles} src="/ryan.gif" alt="ryan's likeness" />
          </div>
          <div className="col-12 col-sm-10">
            <ul style={paragraphStyle}>
              <li>Name: Ryan REDACTED</li>
              <li>DOB: REDACTED</li>
              <li>Hometown: REDACTED, California</li>
              <li>Fears: Hypothermia, The Forbidden &quot;J&quot; Chord, Corrupted Save Files</li>
              <li>Power Rating: B+</li>
              <li>Field of Study: Geobeatometry</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-2">
            <img style={imageStyles} src="/ian.gif" alt="ian's likeness" />
          </div>
          <div className="col-12 col-sm-10">
            <ul style={{ marginTop: '24px', ...paragraphStyle }}>
              <li>Name: Ian REDACTED</li>
              <li>DOB: REDACTED</li>
              <li>Hometown: REDACTED, Missouri</li>
              <li>Fears: Spiders, The Reanimated Corpse of Kenny Rogers, Fingerless Gloves</li>
              <li>
                Power Rating:
                {'\u{1F44D}'}
              </li>
              <li>Field of Study: Cryptofunkology</li>
            </ul>
          </div>
        </div>
        <h4 style={{ marginTop: '24px', paddingTop: '24px', borderTop: '3px dashed rgb(0 255 0)' }}>Staff</h4>
        <ul style={paragraphStyle}>
          <span style={strikeThrough}>
            <li>Name: Krog</li>
            <li>DOB: UNKNOWN</li>
            <li>Hometown: Ancient permafrost hundreds of miles below the surface</li>
            <li>Fears: Raffi</li>
            <li>Power Rating: UNKNOWN</li>
            <li>Field of Study: PhD in History</li>
          </span>
        </ul>
        <ul style={paragraphStyle}>
          <li>Name: The K.R.O.G. System</li>
          <li>DOB: He&apos;s a computer so he doesn&apos;t have one</li>
          <li>Hometown: The Surface of the Moon, where Shane created him</li>
          <li>Fears: Logical Paradoxes, and the Director for some reason</li>
          <li>Power Rating: 600 Watts</li>
          <li>Field of Study: Computer Science</li>
        </ul>
        <ul style={paragraphStyle}>
          <li>Name: The Director</li>
          <li>No available information</li>
        </ul>
      </div>
    </main>
  </div>
);

export default NotFoundPage;
